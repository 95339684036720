<template>
  <div>
    <!-- <el-upload
      ref="upload"
    >
      <i class="el-icon-upload"></i>
    </el-upload> -->
     <Upload
        name="file"
        class="quill-upload"
        :show-upload-list="false"                       
        :on-success="success"
        :format="['jpg','jpeg','png','gif']"             
        :headers='headers'
        :max-size="99999999"
        multiple
        :action="imageAction"    
        ref="upload"         
        style="display:none;"
        >
        <i class="el-icon-upload"></i>
    </Upload>
    <quill-editor
      :disabled="disabled"
      ref="myQuillEditor"
      class="myQuillEditor"
      v-model="editorData"
      @change="onEditorChange($event)"
      :options="editorOption"
    ></quill-editor>
  </div>
</template>
<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import {  quillEditor } from "vue-quill-editor";
// import { baseURL } from "@/config";
import {getCookies} from '@/utils/helper'
import config from '@/config/index'
export default {
  name: "editors",
  components: { quillEditor },
  props: {
    edtorCon: {
      type: String,
      value: "",
    },
    index: {
      type: Number,
      value: 0,
    },
    disabled: {
      type: Boolean,
    },
  },
  data() {
    // 富文本配置
    const toolbarOptions = [
      ["bold", "italic", "underline", "strike"], //加粗，斜体，下划线，删除线
      ["blockquote", "code-block"], //引用，代码块

      [{ header: 1 }, { header: 2 }], // 标题，键值对的形式；1、2表示字体大小
      [{ list: "ordered" }, { list: "bullet" }], //列表
      [{ script: "sub" }, { script: "super" }], // 上下标
      [{ indent: "-1" }, { indent: "+1" }], // 缩进
      [{ direction: "rtl" }], // 文本方向
      [{ size: ["small", false, "large", "huge"] }], // 字体大小
      [{ header: [1, 2, 3, 4, 5, 6, false] }], //几级标题
      [{ color: [] }, { background: [] }], // 字体颜色，字体背景颜色
      [{ font: [] }], //字体
      [{ align: [] }], //对齐方式

      ["clean"], //清除字体样式
      ["image", "video"], //上传图片、上传视频
    ];
    return {
      editorOption: {
        placeholder: "请输入内容",
        modules: {
          toolbar: {
            container: toolbarOptions,
            handlers: {
              // 重写点击组件上的图片按钮要执行的代码
              image: (value) => {
                console.log('123',value)
                let dfff = this.index - 1;
                document.querySelectorAll(".quill-upload .el-icon-upload")[dfff].click();
              },
            },
          },
        },
      },
      editorData: "",
      // 传的id
      // headers: {},
      fileList: [],
      action: "",
      imageAction:config.apiUrl + '/other/uploads',
      headers:{'teeth-token' : getCookies('teeth-token')},
    };
  },
  created() {
    this.editorData = this.edtorCon;    
    // this.action = this.config.apiUrl + "adminapi/config/fileUpload";
    // this.baseUrl = this.config.apiUrl;
    // console.log(this.baseURL);
    // let token = window.localStorage.getItem("token")
    // this.headers = {
    //   "accessToken": token
    // }
  },
  mounted() {
    // if (this.disabled) {
    //   // console.log(.style);
    //   document.querySelectorAll('.ql-toolbar').style.display = "none"
    // }
  },
  methods: {
    success(res, file, fileList) {
      console.log("file",file)
      console.log("fileList",fileList)
      console.log(res);
      // res为图片服务器返回的数据
      // 获取富文本组件实例
      let vm = this;
      console.log(vm)
      let quill = this.$refs.myQuillEditor.quill;

      // 如果上传成功
      if (res.code*1 === 200) {
        // const addImageRange = this.quill.getSelection()
        // const newRange = 0 + (addImageRange !== null ? addImageRange.index : 0)
        const pos = quill.selection.savedRange.index; //这个得注意下，网上很多都是不对的
        // 插入图片到光标位置
        console.log( res.data);
        quill.insertEmbed(pos, "image", res.data.src);
        // 调整光标到最后
        quill.setSelection(length + 1);
      } else {
        // vm.$Message.error('图片插入失败')
      }
      // loading动画消失
      this.quillUpdateImg = false;
    },
    onEditorChange() {
      //内容改变事件
      let obj = {
        editorData: this.editorData,
        index: this.index,
      };
      this.$emit("getContent", obj);
    },
  },
  watch: {
    // focus (event) {
    //   if (this.disabled) {
    //     event.enable(false);
    //   }  //设置富文本编辑器不可编辑
    // },
    serviceUrl(val) {
      this.serviceUrl = val;
    },
    getHeader(val) {
      this.getHeader = val;
    },
    edtorCon() {
      this.editorData = this.edtorCon;
    },
  },
};
</script>

<style scoped>
::v-deep .ql-editor {
  height: 450px;
}

::v-deep .ql-toolbar.ql-snow {
  /*border: none;*/
  border-bottom: 1px solid #aaaaaa;
}

/* ::v-deep .ql-container.ql-snow {
    border: none;
  } */

::v-deep .ql-snow .ql-picker-options .ql-picker-item {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  line-height: 25px !important;
}
</style>
